import React, { FC } from "react";

import { TeamOutlined, ShopOutlined, TagsOutlined, AuditOutlined, UserOutlined } from '@ant-design/icons';

import Quotes from '../quotes';
import Profile from '../profile';
import Clients from '../clients';
import Products from '../products';
import Users from '../users';

interface Route{
    key: string,
    title: string,
    path: string,
    enabled: boolean,
    component: FC<{}>,
    order: number,
    parent?: number,
    icon?: React.ReactElement
}

export const AppRoutes: Array<Route>= [ 
    {
        key: 'profile-route',
        title: 'Mi Perfil',
        path: '/miperfil',
        enabled: true,
        component: Profile,
        icon: React.createElement(UserOutlined),
        order: 0
    },
    {
        key: 'quotes-route',
        title: 'Cotizaciones',
        path: '/cotizaciones',
        enabled: true,
        component: Quotes,
        icon: React.createElement(AuditOutlined),
        order: 1
    },
    {
        key: 'home-route',
        title: 'Cotizaciones',
        path: '/',
        enabled: false,
        component: Quotes,
        icon: React.createElement(AuditOutlined),
        order: 2
    },
    {
        key: 'clients-route',
        title: 'Clientes',
        path: '/clientes',
        enabled: true,
        component: Clients,
        icon: React.createElement(ShopOutlined),
        order: 3
    },
    {
        key: 'products-route',
        title: 'Productos',
        path: '/productos',
        enabled: true,
        component: Products,
        icon: React.createElement(TagsOutlined),
        order: 4
    },
    {
        key: 'users-route',
        title: 'Usuarios',
        path: '/usuarios',
        enabled: true,
        component: Users,
        icon: React.createElement(TeamOutlined),
        order: 5
    }
]

