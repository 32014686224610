import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import Logo from '../../../assets/logo/logo1.png';

// Create styles
const styles = StyleSheet.create({
    body:{
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 50
    },
    header:{
        display:'flex',
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignContent: "flex-end",
        height: 60,
        paddingBottom: 20,
    },
    image:{
        width: "auto",
        height: "100%"
    },
    dateSection:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"flex-end",
        alignContent: "flex-start"
    },
    dateRow:{
        display:"flex",
        flexDirection:"row",
        justifyContent:"space-between"
    },
    label:{
        fontSize: 10,
        fontStyle: "bold",
        paddingRight: 11
    },
    value:{
        fontSize: 10,
        color: "#acabab"
    },
    clientSection:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignContent: "flex-start",        
        //backgroundColor: "#f1efef",
        width: "100%",
        paddingVertical: 3
    },
    clientTitle:{
        backgroundColor: "#f3f0f0",
        fontSize: 11,
        fontStyle: "bold",
        textAlign: "left",
        color: "#89c2fe",
        //paddingHorizontal: 10,
        paddingVertical: 3,
        marginBottom: 3
    },
    clientItem:{
        fontSize: 8,
        //color: "#89c2fe",
        color: "#acabab",
        paddingHorizontal: 5,
        lineHeight: 1.5,
        width:"100%",
        textAlign: "left"
    },
    conditionSection:{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignContent: "flex-start",        
        //backgroundColor: "#f1efef",
        width: "100%",
        paddingVertical: 3
    },
    introSection:{
        fontSize: 8,
        padding: 10,
        width: "100%",
        textAlign:"center",
        color: "#acabab"
    },
    table:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"flex-start",
        alignItems:"flex-start"
    },
    tr:{
        display:"flex",
        flexDirection:"row",
        justifyContent:"flex-start",
        alignItems:"flex-start"
    },
    trn:{
        display:"flex",
        flexDirection:"row",
        justifyContent:"flex-start",
        alignItems:"flex-start",
        backgroundColor: "#f3f0f0"
    },
    th:{
        display:"flex",
        flexDirection:"row",
        justifyContent:"center",
        alignItems:"center",
        color: "#89c2fe",
        fontSize: 11,
        fontStyle: "bold",
        padding: 5
    },
    td:{
        display:"flex",
        flexDirection:"row",
        justifyContent:"center",
        alignItems:"center",
        color: "#acabab",
        fontSize: 8,
        padding: 2
    }    
});
export interface IQuoteDetail{
    title: string;
    description: string;
    price: number;
    counter: number;
    total: number;
}

export interface IQuote {
    date: string;
    code: string;
    client_name: string;
    client_doc: string;
    client_email: string;
    client_buyer: string;
    expiration: number;
    warranty: number;
    delivery_in: number;
    paymentCondition: string;
    quote_detail: Array<IQuoteDetail>;
    discount: number;
    subtotal: number;
    tax: number;
    total: number;
}
let isnth: boolean = true;
// Create Document Component
const QuoteDocumentPdf: React.FC<IQuote> = (props) => (
    <Document>
      <Page size="A4" style={styles.body}>
        <View style={styles.header}>
            <Image src={Logo} style={styles.image}/>            
            <View style={styles.dateSection}>
                <View style={styles.dateRow}>
                    <Text style={styles.label}>Fecha: </Text>
                    <Text style={styles.value}>{props.date} </Text>
                </View>
                <View style={styles.dateRow}>
                    <Text style={styles.label}>Cotización Nº:</Text>
                    <Text style={styles.value}>{props.code } </Text>
                </View>
            </View>
        </View>
        <View style={{display:"flex",justifyContent:"space-between",flexDirection:"column"}}>
            <View style={styles.clientSection}>
                <Text style={styles.clientTitle}> Cliente: </Text>
                <Text style={styles.clientItem}> Razón Social: {props.client_name } </Text>
                <Text style={styles.clientItem}> Nit / CC: {props.client_doc } </Text>
                <Text style={styles.clientItem}> Email: {props.client_email } </Text>
                <Text style={styles.clientItem}> Persona: {props.client_buyer } </Text>
            </View>
            <View style={styles.conditionSection}>
                <Text style={styles.clientTitle}> Condiciones: </Text>
                <View style={{display:"flex",justifyContent:"space-between",flexDirection:"row"}}>
                    <Text style={styles.clientItem}> Vencimiento: {props.expiration } días naturales  </Text>
                    <Text style={styles.clientItem}> Garantía: {props.warranty } meses</Text>
                </View>
                <View style={{display:"flex",justifyContent:"space-between",flexDirection:"row"}}>
                    <Text style={styles.clientItem}> Entrega en: {props.delivery_in } días hábiles </Text>
                    <Text style={styles.clientItem}> Pago: {props.paymentCondition } </Text>
                </View>                
            </View>
        </View>
        <Text style={styles.introSection}>Sr/Sra. {props.client_buyer}, estamos enviando complacidamente la siguiente cotización, con el fin de cumplir las necesidades en su proyecto planeado, quedando atentos a sus comentarios e inquietudes.</Text>
        <View style={styles.table}>
            <View style={styles.trn}>
                <Text style={{...styles.th,width:"20%"}}>Titulo</Text>
                <Text style={{...styles.th,width:"40%"}}>Descripción</Text>
                <Text style={{...styles.th,width:"10%"}}>Precio</Text>
                <Text style={{...styles.th,width:"10%"}}>Cantidad</Text>
                <Text style={{...styles.th,width:"20%"}}>Total</Text>
            </View>
            {props.quote_detail.map((item:IQuoteDetail, i:Number) => {
                isnth = !isnth;
                return (
                    <View style={isnth ? styles.tr : styles.tr} key={`row-${i}`}>
                        {item.title.indexOf('http') === -1
                            ?<Text style={{...styles.td,width:"20%"}}>{item.title}</Text>
                            :<Image src={{ uri: item.title, method: "GET", headers: { "Cache-Control": "no-cache" }, body: "" }} style={{...styles.td,width:"20%"}}/>
                        }
                        <Text style={{...styles.td,width:"40%"}}>{item.description}</Text>                        
                        <Text style={{...styles.td,width:"10%", textAlign:"right"}}>{`$ ${item.price}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                        <Text style={{...styles.td,width:"10%", textAlign:"center"}}>{item.counter}</Text>
                        <Text style={{...styles.td,width:"20%", textAlign:"right"}}>{`$ ${item.total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
                    </View>
                )
            })}
            <View style={styles.trn}>
                <Text style={{...styles.td,width:"80%"}}>Descuento</Text>                
                <Text style={{...styles.td,width:"20%", textAlign:"right"}}>{`${props.discount} %`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
            </View>
            <View style={styles.trn}>
                <Text style={{...styles.td,width:"80%"}}>Subtotal</Text>
                <Text style={{...styles.td,width:"20%", textAlign:"right"}}>{`$ ${props.subtotal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
            </View>
            <View style={styles.trn}>
                <Text style={{...styles.td,width:"80%"}}>IVA</Text>
                <Text style={{...styles.td,width:"20%", textAlign:"right"}}>{`$ ${props.tax}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
            </View>
            <View style={styles.trn}>
                <Text style={{...styles.td,width:"80%"}}>Total</Text>
                <Text style={{...styles.td,width:"20%", textAlign:"right"}}>{`$ ${props.total}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</Text>
            </View>
        </View>
        <Text style={{fontSize: 12, width:"100%", fontStyle: "bold", textAlign: "left", color: "#89c2fe", marginTop:5, marginBottom:5}}>Politicas Comerciales</Text>
        <Text style={{fontSize: 8, width:"100%", textAlign: "justify", color: "#acabab"}}>
            <Text style={{fontSize: 9, fontStyle: "bold", color: "#89c2fe"}}>Entrega: </Text> Los dias establecidos en el encabezado, según la disponibilidad de insumos, al montar el pedido, con aprobación y su debido anticipo, el tiempo de entrega con producto especial puede incrementar según la disponibilidad del proveedor.</Text>
        <Text style={{width:"100%", height:"5px"}}></Text>
        <Text style={{fontSize: 8, width:"100%", textAlign: "justify", color: "#acabab"}}>
            <Text style={{fontSize: 9, fontStyle: "bold", color: "#89c2fe"}}>Instalación: </Text> Las instalaciones fuera del area metropolitana tiene recargos por viaticos y transporte, cualquier cambio en la instalación, debe ser debidamente autorizada por la empresa prestadora de servicios, quienes verifican la posibilidad y si tiene sobrecostos en la solicitud, si el cliente no puede recibir el pedido en la fecha pactada, Se programara por 15 dias más pasados estos dias se iniciara cobro de bodegaje.</Text>
            <Text style={{width:"100%", height:"5px"}}></Text>
        <Text style={{fontSize: 8, width:"100%", textAlign: "justify", color: "#acabab"}}>
            <Text style={{fontSize: 9, fontStyle: "bold", color: "#89c2fe"}}>Condiciones de entrega: </Text>
            Al recibir la mercancía el cliente debe revisar la cantidad según documento y estado de mercancía, si hay productos con defectos o en mal estado se debe informar en dicho documento para evidencia, para instalaciones el cliente debe confirmar el terminado total de obras y libre de escombros o cualquier cosa que pueda afectar el mobiliario. </Text>
            <Text style={{width:"100%", height:"5px"}}></Text>
        <Text style={{fontSize: 8, width:"100%", textAlign: "justify", color: "#acabab"}}>
            <Text style={{fontSize: 9, fontStyle: "bold", color: "#89c2fe"}}>Garantía: </Text>
            La garantía en producto fabricado, comercializado o importado cubre todo lo relacionado en defectos de fábrica en todos sus partes. La garantía en productos de mantenimiento cubre todo lo relacionado con trabajo realizado o pieza (parte) cambiado. El mal manejo o uso en el producto causa perdida de la garantía.</Text>
        <View style={{position:"absolute", bottom:80, left:0, right:0}}>
            <Text style={{fontSize: 9, fontStyle: "bold", color: "#89c2fe", paddingVertical:"3px", textAlign:"center", width: "100%"}}>
                Calle 33 # 64 38 Belén Fátima, Teléfonos: 3228974-3057393600.
            </Text>
            <Text style={{fontSize: 9, fontStyle: "bold", color: "#89c2fe", paddingVertical:"3px", textAlign:"center", width: "100%"}}>
                Pagina web: www.decoroffice.com.co, Email: mercadeo@decoroffice.com.co
            </Text>
            <Text style={{fontSize: 9, fontStyle: "bold", color: "#89c2fe", paddingVertical:"3px", textAlign:"center", width: "100%"}}>
                NO HAY QUE SER GRANDES PARA COMENZAR, HAY QUE COMENZAR PARA SER GRANDES.
            </Text>
        </View>
      </Page>
    </Document>
);

export default QuoteDocumentPdf;