import { Routes, Route, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { AppRoutes } from "./routes";
import Quotes from '../quotes';
import MainLayout from "../layouts/main";

const AppRouter: React.FC = () => {
    const location = useLocation();
    const [transitionStage, setTransistionStage] = useState("OpIn");
    const [displayLocation, setDisplayLocation] = useState(location);

    useEffect(() => {        
        if (location !== displayLocation) setTransistionStage("OpOut");
    }, [location, displayLocation]);


    return (
        <MainLayout>
            <Routes location={location}>
                {AppRoutes.map((route) => (
                    <Route key={route.key} path={route.path} element={<route.component />}/>
                ))}
                <Route path="*" element={<Quotes />}/>
            </Routes>
        </MainLayout>
    );
}

export default AppRouter;