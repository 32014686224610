import { ConfigProvider } from 'antd';

const MainTheme: React.FC<{children: React.ReactElement}> = (props) => {
    return (
        <ConfigProvider
            theme={{
                token:{
                    colorPrimary: '#89c2fefa'
                },
                components:{
                    Breadcrumb:{
                        fontSize: 18
                    }
                }
            }}
        >
            {props.children}
        </ConfigProvider>
    )
}

export default MainTheme;