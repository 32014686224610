import { Button, Drawer, Space, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import { useState } from "react";
import NewQuote from './views/new';

const Quotes: React.FC = () => {
    const [open, setOpen] = useState(false);
    const toogleForm = () => setOpen(!open);
    const columns = [
        {
            title: 'Codigo',
            dataIndex: 'code',
            key: 'code',
        },
        {
            title: 'Cliente',
            dataIndex: 'business_name',
            key: 'business_name',
        },     
        {
            title: 'SubTotal',
            dataIndex: 'subtotal',
            key: 'subtotal',
        },
        {
            title: 'Descuento',
            dataIndex: 'discount',
            key: 'discount',
        },
        {
            title: 'Impuesto',
            dataIndex: 'tax',
            key: 'tax',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: 'Estado',
            dataIndex: 'state',
            key: 'state',
        },
    ]
    return (<>
        <Content style={{ padding: '0 24px', minHeight: 280, overflow:"auto", maxWidth:"100%" }}>
            <Button type="primary" onClick={toogleForm}>Nueva</Button>
            <Table dataSource={[]} columns={columns} style={{maxWidth:"90%"}}/>
            <NewQuote open={open} toogleForm={toogleForm}/>
        </Content>
    </>);
}

export default Quotes;