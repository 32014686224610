import React, { useState } from 'react';
import { Layout } from "antd";
import { Content } from 'antd/es/layout/layout';

import SiteToolbar from "../../components/site-toolbar";
import AppBreadcrumb from '../../components/breadcrumb';
import AppSideBar from '../../components/side-bar';
import './index.css';
import MainTheme from '../../themes/main';

const MainLayout: React.FC<{children: React.ReactElement}> = (props) => {
    const [title, setTitle] = useState<[string]>(["Cotizaciones"]);

    const onSelectedOption = (i:number, item:any) => {
        setTitle([item.label]);
    }

    return(
        <MainTheme>
            <Layout style={{ minHeight: '100vh' }}>
                <SiteToolbar letter="EVA" label="Edy Valencia"/>
                <Content className='main-container'>
                    {/*<AppBreadcrumb items={title}/>*/}
                    <Layout className={'main-content'} >
                        <AppSideBar onSelected={onSelectedOption}/>
                        {props.children}
                    </Layout>
                </Content>
            </Layout>
        </MainTheme>
    );
}

export default MainLayout;