import { PDFViewer } from '@react-pdf/renderer';
import QuoteDocumentPdf, {IQuote} from '../pdf-print';

const DocumentViewer: React.FC<IQuote> = (props) => {
    return (
        <PDFViewer style={{width:"100%", height:"100%"}} >
            <QuoteDocumentPdf {...props}/>
        </PDFViewer>
    )
}

export default DocumentViewer;