import { Button, Drawer, Form, Input, InputNumber, Select, Space, Image, Row, Col, Collapse } from "antd";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { clients, products } from "../../../utils/test-data";
import { useState } from "react";
import Products from "../../../products";
import FormItem from "antd/es/form/FormItem";
import QuoteDocumentPdf, {IQuote, IQuoteDetail} from "../../components/pdf-print";
import ReactPDF from '@react-pdf/renderer';
import DocumentViewer from "../../components/pdf-viewer";

const NewQuote: React.FC<{open:boolean, toogleForm:()=>void}> = (props) => {
    const [openDocument, setOpenDocument] = useState(false);
    const [quote, setQuote] = useState<IQuote | null>(null);
    const toogleViewer = () => setOpenDocument(!openDocument);
    const [client, setClient] = useState<any>(null);
    const [form] = Form.useForm();

    const expiration = [10,15,20,30];
    const warranty = [8,12,18,24];
    const deliveryIn = [8,10,12,15,20,25,30,35,40,45,50,55,60];
    const paymentCondition = ["Contado","Contra Entrega","Crédito 30 días","Anticipo 50% Contra Entrega 50%","Anticipo 60% Contra Entrega 40%","Anticipo 50% Crédito 50% / 30 días","Anticipo 60% Crédito 40% / 30 días"];
    const states = ["Borrador","En Espera","Aprobada","Rechazada","Anulada"];

    const onChange = (key: string | string[]) => {
        console.log(key);
    };

    const onClose = () => {
        
        form.resetFields();
        props.toogleForm();
    }

    const onSubmit = () => {
        const formData = form.getFieldsValue();
        const clientName = clients.find(x => x.id === formData.business_name);
        setQuote({
            date: '01/03/2023',
            code: 'EVA - 000006',
            client_name: clientName?.business_name ?? "",
            client_doc: formData.document,
            client_email: formData.email,
            client_buyer: formData.buyer,
            expiration: formData.expiration,
            delivery_in: formData.delivery_in,
            warranty: formData.warranty,
            paymentCondition: formData.paymentCondition,
            quote_detail: formData.quote_detail?.map((item:any)=>{
                const descr = products.find(x => x.id === item.description);
                return {
                    title: item.img,
                    description:  descr?.description ?? "",
                    price: Number(item.price),
                    counter: Number(item.counter),
                    total: Number(item.total)
                }
            }) ?? [],
            discount: formData?.discount ?? 0,
            subtotal: formData.subtotal,
            tax: formData.tax,
            total: formData.total 
        })
        toogleViewer();
    }


    return (<>
        <Drawer
            title="Nueva Cotización"
            width={"100%"}
            open={props.open}
            onClose={props.toogleForm}
            placement="right"
            bodyStyle={{ paddingBottom: 80 }}
            extra={
            <Space>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={onSubmit} type="primary">Submit</Button>
            </Space>
            }
        >
            <Form 
                name="new_quote"
                layout="vertical"
                autoComplete="off"
                form={form}
                onValuesChange={(e) => {
                    console.log(e)
                    
                }}
            >
                <Collapse defaultActiveKey={['1']} onChange={onChange}>
                    <Collapse.Panel header="Datos Cliente" key="1">
                        <Row justify={"space-between"}>
                            <Col span={11} xs={{span: 24}} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                                <Form.Item label="Cliente" name="business_name" >
                                    <Select 
                                        placeholder="Selecciona un cliente"
                                        options={clients.map((item) => { 
                                            const label = item.type === "JURIDICA" ? item.business_name : `${item.name} ${item.last_name}`;
                                            return {label:label, value:item.id}
                                        })}
                                        onSelect={(e)=>{
                                            const value = clients.find(x => x.id === e);
                                            form.setFieldsValue({document: value?.document, email:value?.email, buyer:value?.buyer});
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label="NIT / CC" name="document" >
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col  span={11} xs={{span: 24}} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                                    <Form.Item label="Email" name="email" >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item label="Comprador / persona" name="buyer" >
                                        <Input />
                                    </Form.Item>
                            </Col>
                        </Row>
                    </Collapse.Panel>
                    <Collapse.Panel header="Condiciones de compra" key="2">
                        <Row justify={"space-between"}>                    
                            <Col xs={{span: 24}} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}>
                                <Form.Item label="Vencimiento" name="expiration" >
                                    <Select 
                                        options={expiration.map((item) => {
                                            return {label:item, value:item}
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col  xs={{span: 24}} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}>
                                <Form.Item label="Garantía" name="warranty" >
                                    <Select 
                                        options={warranty.map((item) => {
                                            return {label:item, value:item}
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col  xs={{span: 24}} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}>
                                <Form.Item label="Entrega en" name="delivery_in" >
                                    <Select 
                                        options={deliveryIn.map((item) => {
                                            return {label:item, value:item}
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col  xs={{span: 24}} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                                <Form.Item label="Condiciones de pago" name="paymentCondition" >
                                    <Select 
                                        options={paymentCondition.map((item) => {
                                            return {label:item, value:item}
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col  xs={{span: 24}} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                                <Form.Item label="Estado" name="state" >
                                    <Select 
                                        options={states.map((item) => {
                                            return {label:item, value:item}
                                        })}
                                    />
                                </Form.Item>
                            </Col>                    
                        </Row>
                    </Collapse.Panel>
                    <Collapse.Panel header="Productos" key="3">
                        <Form.List name="quote_detail">{(fields, { add, remove }) => (<>{fields.map(({ key, name }) => {
                            const currentList = {...form.getFieldsValue()};
                            const currentItem = currentList.quote_detail[key];
                            return(<>                 
                                <Row justify={"space-between"}>
                                    <Col  xs={{span: 24}} sm={{ span: 10 }} md={{ span: 13 }} lg={{ span: 13 }}>
                                        <Form.Item label="Producto" name={[name, 'description']}>
                                            <Select placeholder="Selecciona un producto" options={products.map((item) => { 
                                                return {label:item.description, value:item.id}
                                            })}
                                            onSelect={(e)=>{
                                                const value = products.find(x => x.id === e);
                                                const currentList = {...form.getFieldsValue()};
                                                currentList.quote_detail[key].price = value?.price;
                                                
                                                const total = Number(currentList.quote_detail[key].price);
                                                const counter = Number(currentList.quote_detail[key]?.counter ?? 1);
                                            
                                                currentList.quote_detail[key].total = total * counter;
                                                currentList.quote_detail[key].img = value?.img;
                                                form.setFieldsValue(currentList);
                                            }
                                        }/>                                        
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{span: 24}} sm={{ span: 4 }} md={{ span: 3 }} lg={{ span: 3 }}>
                                        <Form.Item name={[name, 'price']} label="Precio" >
                                            <InputNumber 
                                                style={{width:"100%"}}
                                                placeholder="Valor unitario" 
                                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{span: 24}} sm={{ span: 4 }} md={{ span: 1 }} lg={{ span: 1 }}>
                                        <Form.Item name={[name, 'counter']} label="Cantidad">
                                            <InputNumber min={1} style={{width:"100%"}} onChange={(e)=>{ 
                                                const currentList = {...form.getFieldsValue()};
                                                const currentItem = currentList.quote_detail[key];
                                                currentList.quote_detail[key].total = Number(currentItem.price) * Number(e) ;
                                                form.setFieldsValue(currentList);

                                                const currentForm = {...form.getFieldsValue()};
                                                let total = 0;
                                                currentForm.quote_detail.map((item:any) => {
                                                    total += Number(item.total)
                                                });
                                                const discount = ((currentForm.discount*total)/100);
                                                currentForm.subtotal = Math.round((total - discount)*100)/100;
                                                currentForm.tax = Math.round(currentForm.subtotal*0.19*100)/100;
                                                currentForm.total = Math.round((currentForm.subtotal + currentForm.tax)*100)/100;
                                                form.setFieldsValue(currentForm)
                                            }}/>
                                        </Form.Item>
                                    </Col>
                                    <Col xs={{span: 24}} sm={{ span: 4 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                        <Form.Item name={[name, 'total']} label="Total" >
                                            <InputNumber style={{width:"100%"}}
                                                disabled 
                                                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                               />
                                        </Form.Item>
                                    </Col>
                                    <Col  xs={{span: 24}} sm={{ span: 2 }} md={{ span: 2 }} lg={{ span: 2 }}>
                                        <Form.Item name={[name, 'img']}>                                            
                                            {currentItem?.img.indexOf('http') > -1 
                                                ? <Image src={currentItem?.img} width={"100%"} alt="no hay imagen disponible"/>
                                                : <Input placeholder="cambiar nombre o url de imagen" value={currentItem?.img}/>
                                            }                                       
                                        </Form.Item>
                                    </Col>
                                                                
                                </Row>
                                </>)})
                                }
                                <Form.Item>
                                    <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                                        Incluir Producto
                                    </Button>
                                </Form.Item>
                            </>)}
                        </Form.List>
                    </Collapse.Panel>
                    <Collapse.Panel header="Totales" key="4">
                        <Row justify={"space-between"}>                    
                            <Col xs={{span: 24}} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}>
                                <Form.Item label="Descuento" name="discount" >
                                    <InputNumber 
                                        style={{width:"100%"}}
                                        placeholder="Valor Descuento" 
                                        formatter={(value) => `${value} %`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        onChange={
                                            (e) => {
                                                const currentForm = {...form.getFieldsValue()};
                                                let total = 0;
                                                currentForm.quote_detail.map((item:any) => {
                                                    total += Number(item.total)
                                                });
                                                const discount = ((Number(e)*total)/100);
                                                currentForm.subtotal = Math.round((total - discount)*100)/100;
                                                currentForm.tax = Math.round(currentForm.subtotal*0.19*100)/100;
                                                currentForm.total = Math.round((currentForm.subtotal + currentForm.tax)*100)/100;
                                                form.setFieldsValue(currentForm)
                                            }
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col  xs={{span: 24}} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}>
                                <Form.Item label="Subtotal" name="subtotal" >
                                    <InputNumber 
                                        style={{width:"100%"}}
                                        placeholder="Valor Descuento"
                                        disabled
                                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col  xs={{span: 24}} sm={{ span: 24 }} md={{ span: 7 }} lg={{ span: 7 }}>
                                <Form.Item label="IVA" name="tax" >
                                    <InputNumber 
                                        style={{width:"100%"}}
                                        placeholder="Impuesto"
                                        value={19}
                                        disabled
                                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    />
                                </Form.Item>
                            </Col>
                            <Col  xs={{span: 24}} sm={{ span: 24 }} md={{ span: 11 }} lg={{ span: 11 }}>
                                <Form.Item label="Total" name="total" >
                                    <InputNumber 
                                        style={{width:"100%"}}
                                        placeholder="Total"
                                        value={19}
                                        disabled
                                        formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                    />
                                </Form.Item>
                            </Col>               
                        </Row>        
                    </Collapse.Panel>
                </Collapse>
            </Form>
        </Drawer>
        <Drawer
            title="viewer"
            width={"100%"}
            open={openDocument}
            onClose={toogleViewer}
            placement="right"
            bodyStyle={{ paddingBottom: 80 }}
            extra={
                <Space>
                    <Button onClick={() => { /*ReactPDF.render(<QuoteDocumentPdf />, `../../example.pdf`);*/}}>Imprimir</Button>
                </Space>
            }
        >
            {quote && <DocumentViewer {...quote}/> }
        </Drawer>
    </>);
}

export default NewQuote;


