import React, { useState } from "react";
import { Menu, theme } from "antd";
import { AppRoutes } from "../../router/routes";
import Sider from "antd/es/layout/Sider";
import { useNavigate } from "react-router-dom";
import SiteToolbar from "../site-toolbar";

const AppSideBar: React.FC<any> = (props) => {
    const [index, setIndex] = useState(1);
    const navigation = useNavigate();

    const menuItems = AppRoutes.filter(x => x.enabled).map((item,i) => {
        return {
            key: item.key,
            icon: item.icon,
            label: item.title,
            onClick: () => handledOnclickEvent(i),
            path: item.path
        }
    });

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const handledOnclickEvent = (i:number) => {
        setIndex(i);
        navigation(menuItems[i].path);
        
    }

    return (<>
        <Sider style={{ background: colorBgContainer }} width={"12em"} >
            <Menu
              mode="inline"
              selectedKeys={[menuItems[index].key]}
              defaultSelectedKeys={[menuItems[index].key]}
              defaultOpenKeys={[menuItems[index].key]}
              style={{ height: '100%' }}
              items={menuItems}
            />
        </Sider>
    </>);
}

export default AppSideBar;

