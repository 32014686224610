import { Avatar, Row, Col } from "antd";
import { Header } from "antd/es/layout/layout";
import Logo from "../../assets/logo/logo.png";
import "./index.css";

interface ISiteToolbar{
    letter: string;
    label: string;
}

const SiteToolbar: React.FC<ISiteToolbar> = (props) => {  
    return (<>
        <Row className={"topToolBar"} justify="space-between" align="middle">
            <Col span={5} xs={{span: 13 }} sm={{span: 12}} md={{span: 5}} lg={{span: 5}} className={"logo-container"}>
                <img src={Logo} alt="logo de la compañia" className={"logo"}/>
            </Col>
            <Col span={5} className={'user-menu'} >
                <Avatar className="avatar">{props.letter}</Avatar>
                <label>{props.label}</label>
            </Col>                
        </Row>
    </>)
}

export default SiteToolbar