export const clients = [
    {
        id: 1,
        type:"JURIDICA",
        document:"900553940",
        doc_type:"NIT",
        name:"",
        last_name:"",
        buyer:"",
        business_name:"ACTIDENT S.A.S",
        email:"actident@gmail.com",
        phone:"",
        addresss:"CALLE 33B SUR # 47-41 INT 201",
        active: true,
        created:"21/02/2023",
        updated:"21/02/2023"
    },
    {
        id: 2,
        type:"JURIDICA",
        document:"890927931",
        doc_type:"NIT",
        name:"",
        last_name:"",
        buyer:"",
        business_name:"ELECTRICOS DE COLOMBIA S.A. EN REORGANIZACION",
        email:"fanny.vargas@edecol.com",
        phone:"6042857011",
        addresss:"CR 51 12 SUR 69",
        active: true,
        created:"21/02/2023",
        updated:"21/02/2023"
    },
    {
        id: 3,
        type:"JURIDICA",
        document:"901256496",
        doc_type:"NIT",
        name:"",
        last_name:"",
        buyer:"",
        business_name:"ENLACE FERRETERO S.A.S",
        email:"factura@enlaceferretero.com.co",
        phone:"3167468859",
        addresss:"CL 43 46 27 INT 202",
        active: true,
        created:"21/02/2023",
        updated:"21/02/2023"
    },
    {
        id: 4,
        type:"NATURAL",
        document:"1037603498",
        doc_type:"CC",
        name:"ERIKA",
        last_name:"ALVAREZ",
        buyer:"",
        business_name:"",
        email:"erika.sierra@integralfincasderecreo.com",
        phone:"6043719758",
        addresss:"cr 44 45 42 p2",
        active: true,
        created:"21/02/2023",
        updated:"21/02/2023"
    }
]

export const products = [
    {
        id: 1,
        ref:"SG-0023",
        description:"ANDREW GERENTE (BASIC) BRAZOS GRADUABLES - SISTEMA BASCULANTE - BASE NEGRA.",
        img:"https://gallery.egus.com.co/decoroffice/uploads/images/products/HARRISON%201.jpg",
        price:"608988",
        tax: "19",
        active: true,
        created:"21/02/2023",
        updated:"21/02/2023"
    },
    {
        id: 2,
        ref:"AR-00003",
        description:"ARCHIVADOR METALICO 2X1 CLASICO SIN TAPA (COLORES)",
        img:"https://gallery.egus.com.co/decoroffice/uploads/images/products/HARRISON%201.jpg",
        price:"330000",
        tax: "19",
        active: true,
        created:"21/02/2023",
        updated:"21/02/2023"
    },
    {
        id: 3,
        ref:"DV-00002",
        description:"CABINA PARA PAGOS",
        img:"https://gallery.egus.com.co/decoroffice/uploads/images/products/HARRISON%201.jpg",
        price:"18110000",
        tax: "19",
        active: true,
        created:"21/02/2023",
        updated:"21/02/2023"
    },
    {
        id: 4,
        ref:"FR-00012",
        description:"HOJA DE LIJA",
        img:"",
        price:"2100",
        tax: "19",
        active: true,
        created:"21/02/2023",
        updated:"21/02/2023"
    },
    {
        id: 5,
        ref:"MT-00001",
        description:"MANTENIMIENTO SILLA OPERATIVA (Ajuste Limpieza Lubricacion)",
        img:"",
        price:"127000",
        tax: "19",
        active: true,
        created:"21/02/2023",
        updated:"21/02/2023"
    }
]

export const quotes = [
    {
        id:0,
        code: "EVA-00001",
        expiration: 10,
        warranty: 8,
        delivery_in: 20,
        payment_condition: "Contra Entrega",
        state: "borrador",
        total: "10000000",
        created:"21/02/2023",
        updated:"21/02/2023"
    },
    {
        id:1,
        code: "LOR-00002",
        expiration: 10,
        warranty: 12,
        delivery_in: 10,
        payment_condition: "Contado",
        state: "En espera",
        total: "900000",
        created:"21/02/2023",
        updated:"21/02/2023"
    },
    {
        id:2,
        code: "LCH-00003",
        expiration: 10,
        warranty: 8,
        delivery_in: 10,
        payment_condition: "50% anticipado - 50% a 30 días",
        state: "borrador",
        total: "500000",
        created:"21/02/2023",
        updated:"21/02/2023"
    }
]

export const quoteDetail = [
    {
        id:0,
        id_quote: 0,
        img: "https://gallery.egus.com.co/decoroffice/uploads/images/products/HARRISON%201.jpg",
        counter: 2,
        price: "500000",
        total: "1000000"
    },
    {
        id:1,
        id_quote: 0,
        img: "https://gallery.egus.com.co/decoroffice/uploads/images/products/HARRISON%201.jpg",
        counter: 2,
        price: "500000",
        total: "1000000"
    },
    {
        id:2,
        id_quote: 0,
        img: "https://gallery.egus.com.co/decoroffice/uploads/images/products/HARRISON%201.jpg",
        counter: 2,
        price: "500000",
        total: "1000000"
    },
    {
        id:3,
        id_quote: 0,
        img: "https://gallery.egus.com.co/decoroffice/uploads/images/products/HARRISON%201.jpg",
        counter: 2,
        price: "500000",
        total: "1000000"
    },
]

export const columnsQuoteDetail = [
    {
        title: 'Nombre/Imagen',
        dataIndex: 'name',
        key: 'name',
    },
    {
        title: 'Descripción',
        dataIndex: 'description',
        key: 'description',
    },
    {
        title: 'Valor Unit',
        dataIndex: 'value',
        key: 'value',
    },
    {
        title: 'Cantidad',
        dataIndex: 'counter',
        key: 'counter',
    },
    {
        title: 'Total',
        dataIndex: 'total',
        key: 'total',
    }
];

interface IQuote{
    id: number;
    code: string;
    business_name: string;
    document: string;
    email: string;
    buyer: string;
    expiration: number;
    warranty: number;
    delivery_in: number;
    payment_condition: string;
    subtotal: number;
    discount: number;
    tax: number;    
    total: number;
    state: string;
    created: string;
    update: string;
}
interface IQuoteDetail{
    id: number;
    quote: number;
    title: string;
    description: string;    
    price: number;
    counter: number;
    subtotal: number;
}
  
  

